//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'App',
  data: function data() {
    return {
      content: "<p>hello world</p>",
      editorOption: {},
      model: {
        content: ''
      }
    };
  },
  computed: {
    editor: function editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  methods: {
    // onEditorReady(editor) { // 准备编辑器
    // },
    onEditorBlur: function onEditorBlur() {},
    // 失去焦点事件
    onEditorFocus: function onEditorFocus() {},
    // 获得焦点事件
    onEditorChange: function onEditorChange() {},
    // 内容改变事件
    saveHtml: function saveHtml() {
      console.log(this.content);
    },
    // 保存方法
    submit: function submit() {}
  }
};