var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("富文本编辑器")]),
    _c(
      "div",
      { staticClass: "edit_container" },
      [
        _c("quill-editor", {
          ref: "myQuillEditor",
          attrs: { options: _vm.editorOption },
          on: {
            blur: function($event) {
              return _vm.onEditorBlur($event)
            },
            change: function($event) {
              return _vm.onEditorChange($event)
            },
            focus: function($event) {
              return _vm.onEditorFocus($event)
            }
          },
          model: {
            value: _vm.content,
            callback: function($$v) {
              _vm.content = $$v
            },
            expression: "content"
          }
        }),
        _c("el-button", { attrs: { plain: "" }, on: { click: _vm.saveHtml } }, [
          _vm._v("保存")
        ])
      ],
      1
    ),
    _c("h1", [_vm._v("markdown编辑器")]),
    _c(
      "div",
      { staticClass: "edit" },
      [
        _c("mavon-editor", {
          model: {
            value: _vm.model.content,
            callback: function($$v) {
              _vm.$set(_vm.model, "content", $$v)
            },
            expression: "model.content"
          }
        }),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.submit }
          },
          [_vm._v("发表")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }